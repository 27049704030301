<template>

<div class="text-image-1 inner">

    <div class="text-image-1-text ecc" v-html="item.text" />

    <div class="text-image-1-image">

        <div class="text-image-1-image-image" v-bgimage="item.image" />

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.text-image-1 {
    display: grid;
    grid-template-columns: 1fr 544px;
    grid-gap: 20px;
    padding: 0px 10px;
}

.is-tablet .text-image-1 {
    grid-template-columns: 1fr 266px;
}

.is-mobile .text-image-1 {
    grid-template-columns: 1fr;
}

.text-image-1-text {
    padding-top: 40px;
}

.is-mobile .text-image-1-text {
    padding-top: 0px;
}

.is-desktop .text-image-1-text >>> h1,
.is-desktop .text-image-1-text >>> h2,
.is-desktop .text-image-1-text >>> h3 {
    margin-bottom: 60px!important;
}

.text-image-1-image {
    padding: 40px;
}

.is-mobile .text-image-1-image {
    padding: 20px 0px;
}

.text-image-1-image-image {
    width: 464px;
    height: 464px;
    transform: rotate(7deg);
    background-size: cover;
    background-position: 50% 50%;
}

.is-tablet .text-image-1-image-image {
    width: 200px;
    height: 200px;
}

.is-mobile .text-image-1-image-image {
    width: 200px;
    height: 200px;
    margin: 0px auto;
}

</style>
