import '@/assets/reset.css'
import 'vue-toast-notification/dist/theme-sugar.css'

import Vue from 'vue'

import ScreenBackend from './components/Backend.vue'
import ScreenFrontend from './components/Frontend.vue'

import _ from 'underscore'
import tooltip from 'v-tooltip'
import moment from 'moment-timezone'
import vClickOutside from 'v-click-outside'
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import VueToast from 'vue-toast-notification'

import constants from './constants'
import router from './router'
import store from './store'
import pubsub from './pubsub'
import api from './api'
import util from './util'

import mixInjection from './mixins/injection'
import mixShortcuts from './mixins/shortcuts'
import mixWindow from './mixins/window'

import routerGuard from './router/guard' 

Vue.config.productionTip = false

var requireComponentGlobal = require.context('./components/backend/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app-backend' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

requireComponentGlobal = require.context('./components/frontend/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app-frontend' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

Vue.directive('bgimage', {

	bind: function (el, binding) {

		if (binding.value !== undefined) {

			var file = (_.isObject(binding.value)) ? binding.value.upload : binding.value

			if (file.indexOf('https:') === 0) {
			
				file = file.replace(process.env.VUE_APP_API, process.env.VUE_APP_BUNNY)
			
			}

			el.style.backgroundImage = (binding.value) ? 'url("' + file + '")' : false

		}

	},

	update: function (el, binding) {

		if (binding.value !== undefined) {

			if(binding.value) {

				var file = (_.isObject(binding.value)) ? binding.value.upload : binding.value

				if (file.indexOf('https:') === 0) {
				
					file = file.replace(process.env.VUE_APP_API, process.env.VUE_APP_BUNNY)
				
				}

				el.style.backgroundImage = (binding.value) ? 'url("' + file + '")' : false
				
			} else {

				el.style.backgroundImage = ''

			}

		}

	}

})

Vue.filter('capitalise', function (value) {

	return value.charAt(0).toUpperCase() + value.slice(1)

})

Vue.filter('fromNow', function (value) {

	return (value) ? moment.unix(value).local().fromNow() : ''

})

Vue.filter('localDate', function (value, format) {

	return (value) ? moment.unix(value / 1000).utc().local().format(format) : ''

})

Vue.filter('localDatee', function (value, format) {

	return (value) ? moment.unix(value).utc().local().format(format) : ''

})

router.beforeEach(routerGuard)

Vue.use(tooltip)
Vue.use(vClickOutside)
Vue.use(VueTextareaAutogrowDirective)
Vue.use(VueToast)

Vue.mixin(mixWindow)
Vue.mixin(mixInjection)
Vue.mixin(mixShortcuts)

new Vue({
	_,
	moment,
	router,
	constants,
	util,
	store,
	api,
	pubsub,
	render: h => h((process.env.VUE_APP_TYPE === 'backend') ? ScreenBackend : ScreenFrontend),
	created: function() {

		pubsub.$emit('window.initiated')

	}
}).$mount('#app')
