import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength, minValue, url, email, sameAs } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

    data: function() {
        
        return {
            inline: false,
            is: {
                loading: true,
                saving: false,
                deleting: false
            },
            item: {},
            model: {},
            original: {}
        }

    },

    validations: function() {

        var validations = {}

        this.$_.each(this.validation, function(value, key) {
        
            var definition = {}

            this.$_.each(value, function(params, rule) {
            
                if (rule === 'required') {
                
                    definition['required'] = required
                
                } else if (rule.toLowerCase() === 'maxlength') {
                
                    definition['maxLength'] = maxLength(params)
                
                } else if (rule.toLowerCase() === 'minlength') {
                
                    definition['minLength'] = minLength(params)
                
                } else if (rule === 'selected') {
                
                    definition['required'] = required
                    definition['minValue'] = minValue(1)
                
                } else if (rule === 'url') {
                
                    definition['required'] = required
                    definition['url'] = url
                
                } else if (rule === 'email') {
                
                    definition['required'] = required
                    definition['email'] = email
                
                } else if (rule === 'equals') {
                
                    definition['sameAs'] = sameAs(params)
                
                }

            })

            validations[key] = definition
        
        }.bind(this))

        return {
            model: validations
        }
    
    },

	created: function() {

		this.load()

	},

	watch: {

		model: {

			deep: true,

			handler: function() {

				this.$v.$touch()

                if (this.inline) {
                
                    this.$emit('input', this.$util.copy(this.model))

                }

			}

		}

	},

    computed: {

        validation: function() {
        
            return {}

        },

		title: function() {

            var verb = this.verb || 'Create'

			return (this.isNew) ? verb + ' ' + this.$route.meta.noun : 'Edit ' + this.$route.meta.noun
		
		},
    
        isNew: function() {

			return this.$route.params.id === 'new'

		},

		isValid: function() {

			return !this.$v.$invalid

		},

        isDirty: function() {

			return !this.$_.isEqual(this.model, this.original)

		}

    },

    methods: {
    
        load: function() {

            if(this.inline) {

                var model = this.$util.copy(this.template)

                this.$_.each(this.value || {}, function(value, key) {
                
                    if (model[key] !== undefined) model[key] = value
                
                })

                this.$vueset(this, 'item', model)
                this.$vueset(this, 'original', model)
                this.$vueset(this, 'model', model)

            } else {
        
                this.is.loading = true

                var endpoint = this.endpoint || [this.$route.meta.api, this.$route.params.id]

                this.$api.get(endpoint).then(function(response) {
                
                    this.$vueset(this, 'item', this.$util.copy(response.item))
                    this.$vueset(this, 'original', this.$util.copy(response.model))
                    this.$vueset(this, 'model', this.$util.copy(response.model))

                    this.$_.each(this.references, function(value, key) {
                    
                        if(response[key]) this.$vueset(this.references, key, response[key])
                    
                    }.bind(this))

                    if (this.afterLoad) this.afterLoad()

                    this.is.loading = false
                
                }.bind(this), function() {
                
                    //
                
                })
            
            }
        
        },

        onSaveClick: function() {

            this.$store.dispatch('confirm/cancel')

            if(this.isValid && !this.inline) {

                this.is.saving = true

                var endpoint = this.endpoint || [this.$route.meta.api, this.$route.params.id]
            
                this.$api[(this.isNew) ? 'post' : 'put'](endpoint, this.model).then(function(response) {

                    this.$vueset(this, 'item', this.$util.copy(response.item))
                    this.$vueset(this, 'original', this.$util.copy(response.model))
                    this.$vueset(this, 'model', this.$util.copy(response.model))

                    this.$toastSuccess((this.isNew) ? 'You have successfully created this ' + this.$route.meta.noun + '.' : 'You have successfully saved your changes.')

                    if (this.isNew && this.model.id) {
                    
                        this.$router.replace({
							params: {
								id: this.model.id
							}
						})

                    }

                    this.is.saving = false
                
                }.bind(this), function() {
            
                    this.$toastError('Sorry, your changes could not be saved.')

                    this.is.saving = false
                
                }.bind(this))
                
            }
        
        },

        onUndoClick: function() {

            this.$store.dispatch('confirm/cancel')

			this.$vueset(this, 'model', this.$util.copy(this.original))
        
        },

        onDeleteClick: function() {
        
            this.$store.dispatch('confirm/request', {
                icon: 'action.delete',
                theme: 'red',
                title: this.deleteTitle,
                text: this.deleteText
            }).then(function() {
            
                this.is.deleting = true

                this.$api.delete([this.$route.meta.api, this.$route.params.id]).then(function() {

                    this.$toastSuccess('You have successfully deleted the ' + this.$route.meta.noun + '.')

                    this.$router.push({
                        name: this.$route.meta.section
                    })

                }.bind(this), function() {
            
                    this.$toastError('Sorry, this ' + this.$route.meta.noun + ' could not be deleted.')

                    this.is.deleting = false
                
                }.bind(this))
            
            }.bind(this), function() {
            
                //
            
            })
            
        }
    
    }

}