<template>

<div class="body" :class="{'is-empty': isEmpty, 'is-list': isList}">

    <div class="body-tabs" v-if="tabs">

        <div class="body-tabs-item" :class="{'is-active': currentTab === tab.name}" v-for="(tab, index) in tabs" :key="index" v-on:click="onTabClick(tab.name)">
		
			{{ tab.text }}
			
			<div class="body-tabs-item-count" v-if="tab.count">{{ tab.count }}</div>
			
		</div>

    </div>

    <template v-for="tab in tabs">

		<slot :name="tab.name" v-if="currentTab === tab.name">

		</slot>

	</template>

    <slot></slot>

    <div class="body-empty" v-if="isEmpty">{{ placeholder }}</div>

</div>

</template>

<script>

export default {

    props: ['isEmpty', 'placeholder', 'isList', 'tabs', 'forceTab'],

    data: function() {

		return {
			currentTab: false
		}

	},

	created: function() {

		if (this.tabs) {

			if(this.$route.params.tab) {

				this.currentTab = this.$route.params.tab
			
			} else if(this.forceTab) {
			
				this.currentTab = this.forceTab
			
			} else {
			
				this.$_.each(this.tabs, function(tab) {
				
					if (!this.currentTab && (tab.count === undefined || tab.count || tab.persists)) {
					
						this.currentTab = tab.name
					
					}

				}.bind(this))
			
			}

		}

	},

	watch: {

		forceTab: function(n) {
		
			this.currentTab = n

		},

		tabs: {

			deep: true,

			handler: function() {

				if (this.currentTab) {
				
					var selectedTab = this.$_.findWhere(this.tabs, {
						name: this.currentTab
					})

					if (selectedTab) {

						if (!selectedTab.enabled && selectedTab.enabled !== undefined) this.currentTab = false

					} else {

						this.currentTab = false
					
					}
				
				}

				if(!this.currentTab) {
				
					this.$_.each(this.tabs, function(tab) {
				
						if (!this.currentTab && (tab.count === undefined || tab.count || tab.persists)) {
						
							this.currentTab = tab.name
						
						}

					}.bind(this))
				
				
				}

			}

		}

	},

    methods: {
    
        onTabClick: function(value) {
        
            this.currentTab = value
        
        }
    
    }

}

</script>

<style scoped>

.body {
    padding: 20px;
	overflow: auto;
	min-height: calc(100% - 60px);
}

.body.is-list {
    padding: 10px;
}

.body.is-empty {
    flex-grow: 1;
}

.body-empty {
    font-size: 24px;
    color: #ccc;
    position: absolute;
    left: 50%;
    top: 50%;
	font-weight: 400;
    transform: translate(-50%, -50%);
    text-align: center;
}

.body-tabs {
    display: flex;
    border-bottom: 2px solid #eee;
    height: 40px;
    margin-bottom: 20px;
}

.body-tabs-item {
    border: 2px solid transparent;
    border-radius: 4px 4px 0px 0px;
    padding: 0px 10px;
    font-size: 16px;
    height: 40px;
    line-height: 36px;
    background-color: #fff;
    user-select: none;
	display: flex;
	align-items: center;
    margin-right: 2px;
    border-bottom-color: #eee;
    cursor: pointer;
    color: #343434;
}

.body-tabs-item:not(.is-active):hover {
    background-color: #eee;
    border-color: #eee;
}

.body-tabs-item.is-active {
    border: 2px solid #eee;
    color: #343434;
    border-bottom-color: #fff;
}

.body-tabs-item-count {
	display: inline-block;
	font-size: 12px;
	background-color: #ccc;
	color: #343434;
	margin-left: 5px;
	border-radius: 4px;
	min-width: 16px;
	text-align: center;
	line-height: 16px;
	padding: 2px 4px;
}

</style>
