<template>

<app-backend-input :label="label" :validation="validation" v-on:blur="onInputBlur">

    <div class="select">

        <div class="select-value" :class="{'is-empty': !value, 'is-searchable': isSearchable, 'is-expanded': is.expanded}" v-on:click="onValueClick">
        
            <div class="select-value-text" v-if="!isSearchable">{{ valueText }}</div>

            <input class="select-value-text" type="text" v-model="search" :placeholder="valueText" v-if="isSearchable" v-on:keydown="onInputFocus" v-on:focus="onInputFocus" />

            <i class="fa-solid fa-caret-down select-value-icon" />
            
        </div>

        <div class="select-options" v-if="is.expanded">

            <div class="select-options-item" :data-depth="option.depth" v-for="option in optionsFinal" :key="option.value" v-on:click="onOptionClick(option.value)">{{ option.text }}</div>

        </div>

    </div>

</app-backend-input>

</template>

<script>

export default {

    props: ['label', 'value', 'options', 'isSearchable', 'placeholder', 'canDeselect', 'isNested', 'validation', 'isKey'],

    data: function() {
    
        return {
            is: {
                expanded: false
            },
            search: ''
        }

    },

    computed: {

        optionsFinal: function() {

            return this.$_.filter(this.optionsAll, function(option) {
            
                return option.value !== this.value && (!this.search || option.text.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)

            }.bind(this))
        
        },

        optionsAll: function() {

            var options = []

            if (this.canDeselect && this.value) {
            
                options.push({
                    value: (this.isKey) ? '' : 0,
                    text: this.canDeselect
                })
            
            }

            if (this.isNested) {

                options = this.getLevel('', options)
            
            } else {

                this.$_.each(this.options, function(value, key) {

                    if(this.$_.isObject(value)) {
                    
                        options.push(value)
                        
                    } else {
                    
                        options.push({
                            value: (this.isKey) ? key.toString() : parseInt(key),
                            text: value
                        })

                    }
                

                }.bind(this))
            
            }

            return options
        
        },

        selectedOption: function() {
        
            return this.$_.findWhere(this.optionsAll, {
                value: (this.isKey) ? this.value.toString() : parseInt(this.value)
            })

        },
    
        valueText: function() {

            return (this.selectedOption) ? this.selectedOption.text : this.placeholder
        
        }

    },

    methods: {

        getLevel: function(parent, list) {
        
            var pages = this.$_.where(this.options, {
                parent: parent
            })

            this.$_.each(pages, function(page) {
            
                list.push(page)

                list = this.getLevel(page.value, list)
            
            }.bind(this))

            return list
        
        },

        onInputFocus: function() {
        
            this.is.expanded = true

        },

        onInputBlur: function() {

            this.is.expanded = false
        
        },

        onValueClick: function() {
        
            this.is.expanded = !this.is.expanded

        },
    
        onOptionClick: function(key) {

            this.search = ''
        
            this.$emit('input', (this.isKey) ? key.toString() : parseInt(key))

            this.is.expanded = false
        
        }
    
    }

}

</script>

<style scoped>

.select {
    height: 40px;
}

.select-value {
    border: 2px solid #eee;
    font-size: 16px;
    color: #343434;
    line-height: 36px;
    border-radius: 4px;
    padding: 0px 10px;
    cursor: pointer;
    user-select: none;
}

.select-value.is-expanded {
    border-radius: 4px 4px 0px 0px;
}

.select-value.is-empty:not(.is-searchable) .select-value-text {
    color: #ccc;
}

.select-value-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;
}

.select-value.is-empty .select-value-text::placeholder {
    color: #ccc!important;
}

.select-options {
    display: absolute;
    border: 2px solid #eee;
    border-top: 0px;
    border-radius: 0px 0px 4px 4px;
    z-index: 2;
    max-height: 200px;
    overflow: auto;
}

.select-options-item {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    font-size: 16px;
    color: #343434;
    user-select: none;
}

.select-options-item:nth-child(even) {
    background-color: #f7f7f7;
}

.select-options-item:hover {
    background-color: #eee;
}

.select-options-item[data-depth="1"] {
    padding-left: 40px;
}

.select-options-item[data-depth="2"] {
    padding-left: 80px;
}

.select-options-item[data-depth="3"] {
    padding-left: 120px;
}

.select-options-item[data-depth="4"] {
    padding-left: 160px;
}

.select-options-item[data-depth="1"]:before,
.select-options-item[data-depth="2"]:before,
.select-options-item[data-depth="3"]:before,
.select-options-item[data-depth="4"]:before {
    position: absolute;
    content: '';
    display: block;
    left: 15px;
    top: 50%;
    border-bottom: 1px dashed #ccc;
    width: 15px;
}

.select-options-item[data-depth="1"]:after,
.select-options-item[data-depth="2"]:after,
.select-options-item[data-depth="3"]:after,
.select-options-item[data-depth="4"]:after {
    position: absolute;
    content: '';
    display: block;
    left: 15px;
    top: 0px;
    bottom: 50%;
    border-left: 1px dashed #ccc;
}

</style>
