<template>

<div class="category-2">

    <div class="inner">

        <div class="category-2-title">{{ item.title }}</div>

        <div class="category-2-links">

            <a :href="link.url" class="category-2-links-item" v-for="(link, index) in item.categories" :key="index">

                <div class="category-2-links-item-image" v-bgimage="link.image" />

                <div class="category-2-links-item-title">{{ link.title }}</div>

                <div class="category-2-links-item-text">{{ link.text }}</div>

                <div class="category-2-links-item-button-wrapper">

                    <a :href="link.url" class="category-2-links-item-button">{{ link.button }}</a>

                </div>

            </a>

        </div>

    </div>

</div>

</template>

<script>

export default {

    props: ['item']

}

</script>

<style scoped>

.category-2 {
    background-color: #FBF1E4;
    padding: 100px 0px;
}

.is-tablet .category-2 {
    padding: 64px 10px;
}

.is-mobile .category-2 {
    margin: 20px 0px;
    padding: 48px 10px;
}

.category-2-title {
    text-transform: uppercase;
    color: #00583D;
    font-size: 24px;
    font-weight: 34px;
    font-weight: 400;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
}

.is-tablet .category-2-title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
    text-align: center;
}

.is-mobile .category-2-title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 30px;
    text-align: center;
}

.category-2-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.is-mobile .category-2-links {
    grid-template-columns: 1fr;
    grid-gap: 30px;
}

.category-2-links-item-image {
    background-position: 50% 50%;
    background-size: cover;
    height: 440px;
    margin-bottom: 48px;
}

.is-tablet .category-2-links-item-image {
    height: 240px;
}

.is-mobile .category-2-links-item-image {
    height: 240px;
}

.category-2-links-item-title {
    color: #00583D;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
}

.is-tablet .category-2-links-item-title {
    font-size: 24px;
    line-height: 34px;
}

.is-mobile .category-2-links-item-title {
    font-size: 16px;
    line-height: 24px;
}

.category-2-links-item-text {
    color: #45464B;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 30px;
    text-align: center;
}

.is-tablet .category-2-links-item-text {
    font-size: 14px;
    line-height: 24px;
}

.is-mobile .category-2-links-item-text {
    font-size: 13px;
    line-height: 24px;
}

.category-2-links-item-button-wrapper {
    display: flex;
    justify-content: center;
}

.category-2-links-item-button {
    border: 1px solid #00573C;
    border-radius: 8px;
    display: inline-block;
    text-transform: uppercase;
    color: #00573C;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    height: 70px;
    line-height: 72px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAABLCAMAAADXnC7SAAAAP1BMVEUAAAAAWD0AWDwAWT0AVzwAVz0AYEAAWD0AWD0AWjoAWD0AVz4AWD0AWT0AWEAAWD0AWT0AVz0AWD0AVz4AWD11/yeTAAAAFHRSTlMAwEA/l/AQgGAw4M+gcCDQUK+wb6KSSSIAAAFPSURBVEjH7ZbbjoMgEIbBylk88v7PuvgbpAii7SZ7sfG7aArzDQyDiZIScnLOLJzcQ2oHmLiT0cDeYMuVzSeIoufbLqapL85WaVBbXRiIyuLtVnY6pvxMNwj3JKL0uoA615k8TM5IONOHPGLLCf16uK5UqkRCafWOgDsJXarnCYanRUKvJUwk0kCvsF6EjcVr3xlOagz+CPvFCAxqoH00/Pebje+xiYJJHU4o997Mx14BSt6gzumteh9RSaToq7CBRSD1hQfThQ00qk8DxHP0R9SB05IrHzC/L9LoPX/GTIusuh+vCVF55cenBtHmE59d+RH47ktfvQIm+Oa1c/RHl4Bogk39LvdZMkNTnybBFu2u+vg95T/6EW3DayxCL/sPPfq/u1/Cm50h+EOc458+n4//+I//+I//9/7yArXvARD8HPgZX/mizRGrb9sC5AfRP0w/lq0OrwAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position: 20px 50%;
    background-size: auto 41px;
    padding: 0px 20px 0px 60px;
}

.is-tablet .category-2-links-item-button {
    font-size: 14px;
    line-height: 68px;
}

.is-mobile .category-2-links-item-button {
    background-size: 18px auto;
    font-size: 13px;
    line-height: 52px;
    height: 52px;
    padding: 0px 20px 0px 50px;
}

.category-2-links-item-button:hover {
    color: #fff;
    background-color: #00573C;
}

</style>
